<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="$t('tExaminationDetail')"
      ></el-page-header>
    </div>
    <el-card class="content mt20 bbb">
      <el-form
        label-position="left"
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('examination.create_time')" prop="create_time">
          <div>{{ ruleForm.create_time }}</div>
        </el-form-item>
        <el-form-item :label="$t('examination.title')" prop="title">
          <div>{{ ruleForm.title }}</div>
        </el-form-item>
        <el-form-item :label="$t('examination.order')" prop="order">
          <div>{{ ruleForm.order }}</div>
        </el-form-item>
        <!--
        <el-form-item :label="$t('examination.index')" prop="index">
          <div>{{ ruleForm.index }}</div>
        </el-form-item>
        -->
        <el-form-item :label="$t('examination.type')" prop="type">
          <div>{{ type(ruleForm.type) }}</div>
        </el-form-item>
        <el-form-item :label="$t('examination.level')" prop="level">
          <div>{{ level(ruleForm.level) }}</div>
        </el-form-item>
        <el-form-item :label="$t('examination.status')" prop="status">
          <div>{{ status(ruleForm.status) }}</div>
        </el-form-item>
        <el-form-item :label="$t('examination.content')" prop="content">
          <div v-html="ruleForm.content"></div>
        </el-form-item>
        <el-form-item :label="$t('examination.explain')" prop="explain">
          <div v-html="ruleForm.explain"></div>
        </el-form-item>
        <el-form-item v-if="ruleForm.type === 1 || ruleForm.type === 3" :label="$t('examination.bool')" prop="order">
          <div>{{ bool(ruleForm.bool) }}</div>
        </el-form-item>
        <el-form-item v-if="ruleForm.type === 2 || ruleForm.type === 4" :label="$t('examination.answer')" prop="answer">
          <div>{{ruleForm.answer}}</div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { examinationDetail } from "@/api/index";
export default {
  name: "examimationDetail",
  data() {
    return {
      ruleForm: {
        answer: null,
        bool: 1,
        coding: "",
        content: "",
        create_time: "",
        explain: "",
        level: 1,
        order: 0,
        position: "",
        status: 0,
        title: "",
        type: 1,
        update_time: ""
      },
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    }
  },
  methods: {
      bool(bool) {
      switch (bool) {
        case 0:
          return this.$t("examination.bool0");
        case 1:
          return this.$t("examination.bool1");
      }
    },
    type(type) {
      switch (type) {
        case 1:
          return this.$t("examination.type1");
        case 2:
          return this.$t("examination.type2");
        case 3:
          return this.$t("examination.type3");
        case 4:
          return this.$t("examination.type4");
      }
    },
    level(level) {
      switch (level) {
        case 1:
          return this.$t("examination.level1");
        case 2:
          return this.$t("examination.level2");
        case 3:
          return this.$t("examination.level3");
      }
    },
    status(status) {
      switch (status) {
        case 0:
          return this.$t("examination.status0");
        case 1:
          return this.$t("examination.status1");
      }
    },
    async get_info() {
      const information = await examinationDetail(this.id);
      this.ruleForm = { ...information };
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.get_info();
  },
};
</script>
<style land="scss" scoped>
.el-date-editor {
  margin-right: 10px;
}
.bbb {
  position: relative;
  padding-bottom: 50px;
}
.bbb .btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
